$font-awesome-path: '../fonts';
$icon-font-path: '/fonts/';
$bootstrap-sass-asset-helper: false;

$grid-float-breakpoint: 992px;

$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-size-html-base: 14px;

$body-bg: #fdfdfd;
$text-color: #333;

$brand-primary: #00794c;
$brand-success: #76c279;
$brand-info: #5bc0de;
$brand-warning: #faaf3e;
$brand-danger: #d14d38;
$brand-dark: #444;

$primary: $brand-primary;
$primary-dark: #00603c;
$success: $brand-success;
$info: $brand-info;
$warning: $brand-warning;
$danger: $brand-danger;
$dark: $brand-dark;

$default-margin: 15px;

$pando-sizes: (xs, sm, lg, xl);
$margin-types: (
  xs round($default-margin / 4),
  sm round($default-margin / 2),
  lg round($default-margin * 2),
  xl round($default-margin * 4)
);

$default-text-shadow-color: #000;
$default-text-shadow-h-offset: 0px;
$default-text-shadow-v-offset: 1px;
$default-text-shadow-blur: 0px;

$circle-width: 0.08em;
$circle-width-hover: 0.04em;
$percentage-circle-font: #bbb;
$percentage-circle-font-info: #ddd;
$percentage-circle-bg: #ccc;
$percentage-circle-bg-inner: #f5f5f5;
$percentage-circle-font-dark: #777;
$percentage-circle-font-info-dark: #666;
$percentage-circle-bg-dark: #666;
$percentage-circle-bg-inner-dark: #555;

$default-footer-background: $text-color;
$default-footer-font-color: #fff;
$default-footer-link-color: lighten($brand-primary, 20%);
$default-footer-link-color-hover: lighten($default-footer-link-color, 10%);

$navbar-padding-vertical: 25px;

$grid-gutter-width: 12px;

$rightSidebarWidth: 340px;

$btn-font-weight:                normal !default;
$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;
$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;
$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;
$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;
$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;
$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;
$btn-link-disabled-color:        $btn-default-border !default;

$h1-font-size: 2.2rem;
