.form-show-success,
.form-show-error {
  display: none;
}

.custom-loading {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -.15em;
  animation: custom-loading-spin 1s infinite linear;
  vertical-align: middle;
  border: .2em solid transparentize(#fff, .8);
  border-top-color: #fff;
  border-radius: 50%;
}

.pando-ajax-file-form,
.pando-ajax-form {
  button[type='submit'].disabled {
    position: relative;
    pointer-events: none;

    &:before {
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      content: '';
      background: inherit;
    }

    &:after {
      @include loading-circle(#fff);

      position: absolute;
      top: 50%;
      left: 50%;
      margin: -.5em 0 0 -.5em;
      content: '';
    }
  }
}
